/* Init */
window.tagManagerVars = window.tagManagerVars || {};
window.dataLayer = window.dataLayer || [];

if (typeof (hc_gtm) === 'undefined') {
	hc_gtm = {};
}
/* Init */

hc_gtm.initialiseReceiptPageTracking = function (generalSettings, userSettings, receipt, orderTotalExPostageExVAT) {
	
	window.tagManagerVars.receipt = {};

	window.tagManagerVars.receipt.orderId = receipt.Order.OrderId;
	window.tagManagerVars.receipt.orderDate = receipt.Order.Created;
	window.tagManagerVars.receipt.currencyCode = receipt.CurrencyCode;
	window.tagManagerVars.receipt.currencyPrefix = receipt.CurrencyPrefix;
	window.tagManagerVars.receipt.orderTotal = receipt.Order.Total.toFixed(2);
	window.tagManagerVars.receipt.shippingTotal = receipt.Order.ShippingTotal.toFixed(2);
	window.tagManagerVars.receipt.tax = receipt.Order.Tax.toFixed(2);
	window.tagManagerVars.receipt.orderTotalExPostageExVAT = orderTotalExPostageExVAT;
	window.tagManagerVars.receipt.subTotal = receipt.Order.SubTotal.toFixed(2);
	window.tagManagerVars.receipt.grandTotal = receipt.Order.GrandTotal.toFixed(2);
	window.tagManagerVars.receipt.promoCode = receipt.Order.PromoCode;
	window.tagManagerVars.receipt.customerId = receipt.Order.Customer.CustomerId;
	window.tagManagerVars.receipt.customerEmail = receipt.Order.Customer.EmailAddress;
	window.tagManagerVars.receipt.customerFullName = receipt.Order.AddressShipping.FullName;
	window.tagManagerVars.receipt.customerTitle = receipt.Order.AddressShipping.Title;
	window.tagManagerVars.receipt.customerFirstName = receipt.Order.AddressShipping.FirstName;
	window.tagManagerVars.receipt.customerLastName = receipt.Order.AddressShipping.LastName;
	window.tagManagerVars.receipt.discountTotal = receipt.Order.DiscountTotal;
	window.tagManagerVars.receipt.affiliation = receipt.WebStoreDefaultUrl;
	window.tagManagerVars.receipt.hostname = window.location.host;
	window.tagManagerVars.receipt.origin = window.location.origin;
	window.tagManagerVars.receipt.isGuest = receipt.IsGuest;
	window.tagManagerVars.receipt.shipAddressPostCode = receipt.Order.AddressShipping.PostCode;
	window.tagManagerVars.receipt.existingCustomer = receipt.Order.Customer.Existing;
	window.tagManagerVars.receipt.shippingTypeId = receipt.Order.Shipping.ShippingTypeId;

	window.tagManagerVars.receipt.products = [];
	receipt.Order.OrderItems.forEach(function (element) {
		var product = {};
		product.code = element.Code;
		product.shortCode = element.Code.split('-')[0];
		product.name = element.Name;
		product.price = element.LineSoldPrice > 0 ? (element.LineSoldPrice / element.Quantity).toFixed(2) : 0;
		product.quantity = element.Quantity;
		product.imageUrl = element.ImagePath;
		product.productFullUrl = element.ProductFullUrl;
		product.productCategoryName = element.ProductCategoryName;
		product.primaryCategoryPath = element.PrimaryCategoryPath;
		product.ean = element.Ean;
		product.brand = generalSettings.BrandName;

		window.tagManagerVars.receipt.products.push(product);
	});
};

hc_gtm.initialiseProductListingPageTracking = function (criteoSettings, data, searchViewModel, breadcrumbs) {

	window.tagManagerVars.productListingPage = {};
	window.tagManagerVars.productListingPage.data = data;

	if (data.ecommerce.impressions.length > 0) {
		window.tagManagerVars.productListingPage.categoryName = data.ecommerce.impressions[0].category;
		window.tagManagerVars.productListingPage.productIDs = [];

		$.each(data.ecommerce.impressions, function (idx, x) {
			window.tagManagerVars.productListingPage.productIDs.push(String(x.id));
		});
	}

	window.tagManagerVars.productListingPage.searchTerms = searchViewModel.Query;

	if (window.tagManagerVars.productListingPage.categoryName !== "undefined" && window.tagManagerVars.productListingPage.categoryName) {
		window.tagManagerVars.productListingPage.googleTaxonomy
			= window.tagManagerVars.productListingPage.categoryName.indexOf('/') === 0
			? window.tagManagerVars.productListingPage.categoryName.substring(1) : window.tagManagerVars.productListingPage.categoryName;

		window.tagManagerVars.productListingPage.googleTaxonomy
			= window.tagManagerVars.productListingPage.googleTaxonomy.replaceAll("/", ">");
		
	}

	window.tagManagerVars.productListingPage.breadcrumb  = breadcrumbs;
	window.tagManagerVars.productListingPage.breadcrumbUrl = breadcrumbs[breadcrumbs.length-1]?.Url;
};

hc_gtm.initialiseAddToCartTracking = function (data) {

	window.tagManagerVars.addToCart = {};
	window.tagManagerVars.addToCart.productCode = data.productCode;
	window.tagManagerVars.addToCart.shortCode = data.productCode.split("-")[0];
	window.tagManagerVars.addToCart.productName = data.productName;
	window.tagManagerVars.addToCart.currencyCode = data.currencyCode;
	window.tagManagerVars.addToCart.price = data.salePrice.toFixed(2);
	window.tagManagerVars.addToCart.ean = data.ean;
	window.tagManagerVars.addToCart.quantity = data.quantity;
};

hc_gtm.initialiseProductDetailPageTracking = function (gender, product, breadcrumbs) {

	window.tagManagerVars.productLayout = {};
	window.tagManagerVars.productLayout.gender = gender;

	window.tagManagerVars.productLayout.details = {};
	window.tagManagerVars.productLayout.details.productCode = product.Code;
	window.tagManagerVars.productLayout.details.shortCode = product.Code.split("-")[0];
	window.tagManagerVars.productLayout.details.productName = product.Name;
	window.tagManagerVars.productLayout.details.currencyCode = product.CurrencyCode;
	window.tagManagerVars.productLayout.details.price = product.SalePrice.toFixed(2);
	
	window.tagManagerVars.productLayout.details.breadcrumbs = breadcrumbs;
	window.tagManagerVars.productLayout.details.breadcrumbUrl = breadcrumbs[breadcrumbs.length-1]?.Url;
	
	var googleTaxonomy = product.ProductAttributes.filter((e) => e.Key === "google_category")?.[0]?.AttributeValues?.[0]?.Name;
	window.tagManagerVars.productLayout.details.epsilonTaxonomy = gender+" > "+googleTaxonomy;
};

hc_gtm.initialiseBasketTracking = function (criteoSettings, basket, legacy, addedToBag) {

	window.tagManagerVars.checkout = {};
	window.tagManagerVars.checkout.basket = basket;
	window.tagManagerVars.checkout.legacy = legacy;
	window.tagManagerVars.checkout.addedToBag = addedToBag;
	window.tagManagerVars.checkout.currency = basket.CurrencyCode;

	hc_gtm.setOmetriaBasket(basket);

	if (criteoSettings.Enabled) {
		window.tagManagerVars.checkout.criteoBasket = hc_gtm._criteo_populateProductPriceLines(basket.BasketItems, basket.CurrencyCode, true);
	}

	window.dataLayer.push({
		"event": "basketUpdated"
	});
};

// Private methods - Start
hc_gtm.setOmetriaBasket = function (basket) {

	window.tagManagerVars.checkout.ometriaBasket = [];

	for (var i = 0; i <= basket.BasketItems.length - 1; i++) {

		var currentItem = basket.BasketItems[i];
		var quantity = 0;

		for (var j = 0; j <= basket.BasketItems.length - 1; j++) {
			if (currentItem.Code == basket.BasketItems[j].Code) {
				quantity += basket.BasketItems[j].Quantity;
			}
		}

		if (!window.tagManagerVars.checkout.ometriaBasket.some(function (bi) {
			return bi.Code == currentItem.Code;
		}))
		{
			window.tagManagerVars.checkout.ometriaBasket.push(
				{
					"Code": currentItem.Code,
					"Quantity": quantity,
					"ProductUrl": currentItem.ProductUrl,
					"Price": currentItem.SalePrice
				});
		}
	}
};

hc_gtm._criteo_populateProductPriceLines = function (prods, currency, isBasket) {
	var poundConversionRate = hc_gtm._getPoundConversionRate(currency);
	var pArray = [];

	for (var loop = 0; loop <= prods.length - 1; loop++) {
		pArray.push(
			{
				id: isBasket ? prods[loop].Code : prods[loop].id,
				price: isBasket ? parseInt(parseFloat(Math.round(prods[loop].SalePrice * 100) / 100).toFixed(2)) * poundConversionRate
					: parseInt(parseFloat(Math.round(prods[loop].price * 100) / 100).toFixed(2)) * poundConversionRate,
				quantity: isBasket ? parseInt(prods[loop].Quantity) : parseInt(parseFloat(Math.round(prods[loop].quantity * 100) / 100).toFixed(0))
			});
	}

	return pArray;
};

hc_gtm._getPoundConversionRate = function (currency) {
	var poundConversionRate = 1;

	switch (currency) {
		case "GBP":
			poundConversionRate = 1.00;
			break;
		case "EUR":
			poundConversionRate = 0.85;
			break;
		case "USD":
			poundConversionRate = 0.77;
			break;
		case "AUS":
			poundConversionRate = 0.52;
			break;
		default:
	}

	return poundConversionRate;
};
// Private methods - End
